import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import Autosuggest from "react-autosuggest"
import useSearchQuery from './../graphql/use-search-query';
import * as JsSearch from 'js-search';

interface Suggestion {
    title: string
    url: string
}

export default function SearchBox() {
    const [state, setState] = useState<{
        value: string
        suggestions: Suggestion[]
    }>({
        value: "",
        suggestions: [],
    })

    const suggestions = [];

    const all_data = useSearchQuery();
    const search = new JsSearch.Search(['node', 'id']);
    search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    search.addDocuments(all_data.allMdx.edges);
    search.addIndex(['node', 'frontmatter', 'title']);
    search.addIndex(['node', 'frontmatter', 'description']);
    search.addIndex(['node', 'body']);

    const getSuggestions = (value: string) => {
        const inputValue = value.trim().toLowerCase()
        const inputLength = inputValue.length

        if (inputLength === 0) {
            return [];
        }
        const g = search.search(inputValue);
        if (!g) {
            return [];
        }
        const truncated = g.slice(0, 8);
        let final_sugg = [];

        truncated.forEach(portion => {
            final_sugg.push({
                title: portion.node.frontmatter.title,
                url: portion.node.fields.slug
            })
        });
        return final_sugg;
    }

    const getSuggestionValue = (suggestion: Suggestion) => suggestion.title

    const renderSuggestion = (suggestion: Suggestion) => (
        <Link
            to={suggestion.url}
            className="block bg-bg px-4 py-2 hover:bg-bgalt border border-medium"
        >
            {suggestion.title}
        </Link>
    )

    const onChange = (event, { newValue }) => {
        setState(p => ({
            ...p,
            value: newValue,
        }))
    }

    const onSuggestionsFetchRequested = ({ value }) => {
        // console.log(value);
        setState(p => ({
            ...p,
            suggestions: getSuggestions(value),
        }))
    }

    const onSuggestionsClearRequested = () => {
        setState(p => ({
            ...p,
            suggestions: [],
        }))
    }

    return (
        <div className="bg-gradient-primary p-2px relative">
            <Autosuggest
                suggestions={state.suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                inputProps={{
                    className:
                        "block w-full outline-none px-4 py-2 focus:outline-none bg-bg text-color-default",
                    placeholder: "Search",
                    type: "text",
                    value: state.value,
                    onChange: onChange,
                }}
                renderSuggestionsContainer={renderSuggestionsContainer}
                theme={{
                    container: "react-autosuggest__container",
                    containerOpen: "react-autosuggest__container--open",
                    input: "react-autosuggest__input",
                    inputOpen: "react-autosuggest__input--open",
                    inputFocused: "react-autosuggest__input--focused",
                    suggestionsContainer:
                        "react-autosuggest__suggestions-container",
                    suggestionsContainerOpen:
                        "react-autosuggest__suggestions-container--open",
                    suggestionsList: "react-autosuggest__suggestions-list",
                    suggestion: "react-autosuggest__suggestion",
                    suggestionFirst: "react-autosuggest__suggestion--first",
                    suggestionHighlighted:
                        "react-autosuggest__suggestion--highlighted",
                    sectionContainer: "react-autosuggest__section-container",
                    sectionContainerFirst:
                        "react-autosuggest__section-container--first",
                    sectionTitle: "react-autosuggest__section-title",
                }}
            />
        </div>
    )
}

function renderSuggestionsContainer({ containerProps, children, query }) {
    return (
        <div
            {...containerProps}
            className="suggestions-container absolute z-50 mt-2px min-w-full right-0"
        >
            {children}
        </div>
    )
}

function onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
) {
    navigate(suggestion.url)
}
