import { graphql, useStaticQuery } from 'gatsby';

const useSearchQuery = () => {
    const data = useStaticQuery(graphql`
        query SearchBlogListQuery {
          allMdx(filter: {fields: {sourceName: {eq: "blog"}}}, sort: {fields: [frontmatter___date], order: DESC}) {
            edges {
              node {
                id
                frontmatter {
                  title
                  description
                }
                fields {
                  slug
                }
                body
              }
            }
          }
        }
    `);
    return data;
};

export default useSearchQuery;
